<template>
  <div class="display-check-lists">
     <h1 v-if="check_lists.length==0" style="color:#fff; text-align:center;">לא קיימות רשומות</h1>
    <template v-for="(list,index) in check_lists" :key="index">
        <div v-if="list.role=='מנהלי רשת' && role>=99" class="display-check-lists-section">
            <div>
                <h3>שם: {{list.name}}</h3>
                <h3>תיאור: {{list.description}}</h3>
                <h3>תפקיד: {{list.role}}</h3>
            </div>
            <div>
                <div class="check-btn">
                    <i @click="handle_list(list)" class="material-icons">check</i>
                </div>
            </div>
        </div>
        <div v-if="list.role!='מנהלי רשת'" class="display-check-lists-section">
            <div>
                <h3>שם: {{list.name}}</h3>
                <h3>תיאור: {{list.description}}</h3>
                <h3>תפקיד: {{list.role}}</h3>
            </div>
            <div>
                <div class="check-btn">
                    <i @click="handle_list(list)" class="material-icons">check</i>
                </div>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../../../store'
import { computed } from '@vue/runtime-core'
export default {
    emits:['list'],
    props:['check_lists'],
    setup(_,{emit}){

        const role = ref(computed(()=>{
            return store.state.role
        }))
        const handle_list = (list) => {
            emit('list',list)
        }
        return{
            handle_list,
            role,
        }
    }
}
</script>

<style scoped>
    .display-check-lists{
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 10px 0;
        overflow: hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: 100px;
        grid-gap: 5px;

    }
    @media screen and (max-width: 600px) {
        .display-check-lists{
            grid-template-columns: repeat(1,1fr);
        }
    }
    .display-check-lists-section{
        background: whitesmoke;
        border-radius: 5px;
        display: flex;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
    .display-check-lists-section > div{
        
        height: 100%;
        padding: 5px; 
        color: #333;
        line-height: 1.5;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    }
    @media screen and (max-width: 600px) {
         .display-check-lists-section > div{
             line-height: 1.2;
         }
    }
    .display-check-lists-section > div:nth-child(1){
        width: 70%;
    }
    .display-check-lists-section > div:nth-child(2){
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check-btn{
        width: 50px;
        height: 50px;
        background: var(--success);
        border-radius: 50px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
    .check-btn i {
        font-size: 35px;
    }
    
</style>