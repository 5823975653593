
import { computed, ref } from "vue";
import { projectFirestore } from "../../../../../firebase/config";
import store from '../../../../../store'

const user = ref(computed(()=>{
    return store.state.user
}))

const get_check_lists_formts_from_db = async()=>{
    const docs = await projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').get()
    const data = docs.docs.map(doc=>doc.data())
    return data
}

const save_new_check_list_in_db = async(check_list)=>{
    const doc = projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').doc(check_list.uid).collection('Submits').doc()
    await doc.set({
        check_list_uid:doc.id,
        uid_belong_to: check_list.uid,
        description:check_list.description,
        role:check_list.role,
        name:check_list.name,
        user_uid: store.state.user.uid,
        user_name: store.state.user.display_name,
        results: check_list.check_lists,
        start_time: check_list.start_time,
        branche: check_list.branche,
        end_time: new Date()
    })
}

const get_all_branches = async()=>{
    const docs = await projectFirestore.collection('Shivuk')
    .doc('Shivuk').collection('Branches').get()
    return docs.docs.map(doc=>doc.data().name)
}

export{
    get_check_lists_formts_from_db,
    save_new_check_list_in_db,
    get_all_branches,
    user,
}